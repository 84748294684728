<template>
    <div class="preprint-search-page">
        <HeaderAnnualMeeting />
        <div style="height: 400px;">
            <HeaderPreprint v-if="$route.name =='pS'" />
            <HeaderOa v-if="$route.name =='oaS'" />
        </div>
        <div class="wid1200">
            <bread-crumb :pN="pN" :childName="pageName"></bread-crumb>
        </div>

        <div class="search wid1200">
            <div class="left-search">

                <div v-if="$route.name=='oaS' && (!$route.query.source) && oaData.length>0">
                    <h5><img src="../assets/img/conicon.png" alt="" />OA类型</h5>
                    <ul class="oa-ul">
                        <li v-for="(item, index) in oaData" :key="index"
                            :class="[activeOa ==item.name ? 'active-left' : '']">
                            <a :title="item.name+'('+item.count+')'"
                                @click="switchSide(item, 'OA类型')">{{item.name}}<span>（{{item.count}}）</span></a>
                            <i v-if="activeOa==item.name" class="el-icon-close" @click="deleteLeft(0, item)"></i>
                        </li>

                    </ul>
                </div>
                <div v-if="$route.name!='pS' && $route.name!='oaS' && subjectData.length>0">
                    <h5><img src="../assets/img/conicon.png" alt="" />内容分类</h5>
                    <ul>
                        <li v-for="(item, index) in subjectData" :key="index"
                            :class="[activeResource ==item.name ? 'active-left' : '']">
                            <a :title="item.name+'('+item.count+')'"
                                @click="switchSide(item, '内容分类')">{{item.name}}<span>（{{item.count}}）</span></a>
                            <i v-if="activeResource==item.name" class="el-icon-close" @click="deleteLeft(1, item)"></i>
                        </li>

                    </ul>
                </div>
                <div class="class-wrap" v-if="classifyData.length>0">
                    <h5><img src="../assets/img/subicon.png" alt="" />学科</h5>
                    <ul>
                        <li v-for="(item, index) in classifyData" :key="index"
                            :class="[activeClass ==item.name ? 'active-left' : '']">
                            <a :title="item.name+'('+item.count+')'"
                                @click="switchSide(item, '学科')">{{item.name}}<span>（{{item.count}}）</span></a>
                            <i v-if="activeClass ==item.name" class="el-icon-close" @click="deleteLeft(2, item)"></i>
                        </li>
                    </ul>
                </div>
                <div class="year-wrap" v-if="timeData.length>0">
                    <h5><img src="../assets/img/yearicon.png" alt="" />年份</h5>
                    <ul>
                        <li v-for="(item, index) in timeData" :key="index"
                            :class="[activeDate ==item.name ? 'active-left' : '']">
                            <a :title="item.name+'('+item.count+')'"
                                @click="switchSide(item, '年份')">{{item.name}}<span>（{{item.count}}）</span></a>
                            <i v-if="activeDate ==item.name" class="el-icon-close" @click="deleteLeft(3, item)"></i>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="right-search">
                <div class="second-search-wrap">
                    <!-- <div class="second-search" v-if="$route.name != 'high'">
                        <input class="second-title second-input" type="text" placeholder="请输入标题"
                            v-model="secondTitle" />
                        <input class="second-author second-input" type="text" placeholder="请输入作者"
                            v-model="secondAuthor" />
                        <input class="second-keywords second-input" type="text" placeholder="请输入关键词"
                            v-model="secondKeyword" />

                        <div class="second-date">
                            <el-date-picker style="width: 149px" v-model="secondStartDate" type="date"
                                placeholder="在线起始日期" value-format="yyyy-MM-DD">
                            </el-date-picker>
                            -
                            <el-date-picker style="width: 149px" v-model="secondEndDate" type="date"
                                placeholder="在线截止日期" value-format="yyyy-MM-DD">
                            </el-date-picker>
                        </div>
                        <button @click="secondSearch">在结果中检索</button>
                    </div> -->
                    <div class="search-option">
                        <div class="search-option1">
                            <!-- <p v-if="$route.name != 'secLibrary'"> -->
                                <a  v-if="$route.name != 'secLibrary'" :title="$route.name=='high' ? highKey : $route.query.searchKey">检索：{{$route.name=='high' ? highKey : $route.query.searchKey  }}
                                </a>
                            <!-- </p> -->
                            <a class="total">共找到（<span>{{total}}</span>）条</a>
                            <div class="search-option2">排序：
                                <span :class="['sort1', sort==1 ? 'active-sort' : '']" @click="switchSort(1)">相关度</span>
                                |
                                <span :class="['sort2', sort==2 ? 'active-sort' : '']" @click="switchSort(2)">在线时间</span>
                            </div>
                        </div>

                    </div>
                    <ul class="result-list">
                        <li v-for="(item, ind) in resultData" :key="ind+888">

                            <div class="tag-book" v-if="item.resourceType == 'COLLECTED_PAPERS'"
                                style="width: 148px; height: 198px; margin-right: 13px;">
                                <img :src="item.imgPath" @click="goCollected(item.id, item.name)" alt="" />
                            </div>
                            <div class="tag-book" v-if="item.resourceType == 'BOOK'"
                                style="width: 148px; height: 198px; margin-right: 13px;">
                                <img :src="item.img" @click="goCollectedDetail(item.id, item.title)" alt="" />
                            </div>
                            <div class="tag-wenji" v-if="item.resourceType == 'WENJI'"
                                style="width: 148px; height: 198px; margin-right: 13px;">
                                <img :src="item.img" @click="goCollectedDetail(item.id, item.title)" alt="" />
                            </div>

                            <div :class="['right-result', item.imgPath? 'w-result' : '']"
                                v-if="item.resourceType == 'COLLECTED_PAPERS'">
                                <h4>
                                    <h5 @click="goCollected(item.id, item.name)" v-html="item.name"></h5>
                                    <!-- 反着顺序 -->
                                    <p class="included-database" v-if="item.cnki==1 || item.ami==1 || item.amiCore==1 || item.cssci==1">
                                        <span v-if="item.directoriesDate==1">CNI名录集刊 </span>
                                        <span v-if="item.cnki==1">CNKI</span>
                                        <span v-if="item.ami==1">AMI入库</span>
                                        <span v-if="item.amiCore==1">AMI核心</span>
                                        <span v-if="item.cssci==1">CSSCI</span>
                                    </p>
                                </h4>

                                <p>主编：<span v-html="item.author"></span></p>
                                <p>所属学科：<span v-html="item.subject"></span></p>
                                <p>创刊时间：<span>{{item.firstDate}}</span></p>
                                <p>主办单位：<span>{{item.insNames}}</span></p>
                                <p>简介：<span v-html="item.description"></span></p>
                            </div>
                            <div :class="['right-result', item.img? 'w-result' : '']"
                                v-if="item.resourceType == 'BOOK'">
                                <h4>
                                    <h5 @click="goCollectedDetail(item.id, item.title)" v-html="item.title"></h5>
                                    <!-- 反着顺序 float right-->
                                    <p class="included-database" v-if="item.cnki==1 || item.ami==1 || item.amiCore==1 || item.cssci==1">
                                        <span v-if="item.directoriesDate==1">CNI名录集刊 </span>
                                        <span v-if="item.cnki==1">CNKI</span>
                                        <span v-if="item.ami==1">AMI入库</span>
                                        <span v-if="item.amiCore==1">AMI核心</span>
                                        <span v-if="item.cssci==1">CSSCI</span>
                                    </p>
                                </h4>
                                <p>主编：<span v-html="item.author"></span></p>
                                <p>所属学科：<span v-html="item.subject"></span></p>
                                <p>创刊时间：<span>{{item.releaseDate}}</span></p>
                                <p>主办单位：<span>{{item.insNames}}</span></p>
                                <p>简介：<span v-html="item.abstractCn"></span></p>
                            </div>
                            <div :class="['right-result', item.img? 'w-result' : '']"
                                v-if="item.resourceType == 'WENJI'">
                                <h5 @click="goCollectedDetail(item.id, item.title)" v-html="item.title"></h5>
                                <p>主编：<span v-html="item.author"></span></p>
                                <p>所属学科：<span v-html="item.subject"></span></p>
                                <p>创刊时间：<span>{{item.releaseDate}}</span></p>
                                <p>主办单位：<span>{{item.insNames}}</span></p>
                                <p>简介：<span v-html="item.abstractCn"></span></p>
                            </div>
                            <div class="right-result article-result" v-if="item.resourceType == 'ARTICLE' || item.resourceType == 'ALONE_ARTICLE' || item.resourceType == 'NEWS'">
                                <h5 class="article-title" v-if="item.searchTitle" @click="goArticleDetail(item)">
                                    <p v-if="item.openAccessStatus == 1"
                                        style="box-sizing: border-box; padding-right: 4px;">
                                        <img
                                            src="../assets/img/lock.png" alt="" style="width: 20px" />

                                    </p>
                                    <p v-else :class="[item.resourceType == 'ALONE_ARTICLE'? 'article-icon-preprint' : item.oaType =='OA视频' ? 'article-icon-video' : item.oaType =='OA课件' ? 'article-icon-pdf' : item.oaType =='OA数据集' ? 'article-icon-collect' : 'article-icon-p']"></p>

                                    <!-- OA课件 OA数据集  OA视频 -->
                                    <!-- ../assets/img/oa/icon-pdf.png -->
                                    <!-- ../assets/img/oa/icon-ssap.png -->
                                    <p :class="[item.oaType !='OA课件' ? 'article-title-pdf' : item.mediaPath? '' : 'article-title-auto-pdf']" v-html="item.searchTitle"></p>
                                </h5>
                                <h5 class="article-title" v-else @click="goArticleDetail(item)">
                                    <p v-if="item.openAccessStatus == 1"
                                        style="box-sizing: border-box; padding-right: 4px;"><img
                                            src="../assets/img/lock.png" alt="" style="width: 20px" /></p>
                                            <p v-else :class="[item.resourceType == 'ALONE_ARTICLE'? 'article-icon-preprint' : item.oaType =='OA视频' ? 'article-icon-video' : item.oaType =='OA课件' ? 'article-icon-pdf' : item.oaType =='OA数据集' ? 'article-icon-collect' : 'article-icon-p']">{{item.oaType =='OA课件' ? 'pdf' : ''}}</p>
                                    <p  :class="[item.oaType !='OA课件' ? 'article-title-pdf' : item.mediaPath? '' : 'article-title-auto-pdf']"  v-html="item.title"></p>
                                </h5>
                                <p>作者：<span v-html="item.author"></span></p>
                                <!-- <p>所属学科：<span>佛学</span></p> -->
                                <!-- createDate -->
                                <!-- releaseDate -->
                                <p>在线时间：<span>{{item.releaseDate}}</span></p>
                                <p>关键词：<span v-html="item.keyWordCn"></span></p>
                                <!-- <p>摘要：<span v-html="item.abstractEn"></span></p> -->
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="pagination-wrap">
                    <el-pagination @current-change="handleCurrentChange" :current-page="currentPage"
                        :page-size="pageSize" layout="total, prev, pager, next, jumper" :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BreadCrumb from '../components/BreadCrumb'
import HeaderAnnualMeeting from '../components/HeaderAnnualMeeting'
import HeaderPreprint from '../components/HeaderPreprint'
import HeaderOa from '../components/HeaderOa'

export default {
    name: '',
    components: { BreadCrumb, HeaderAnnualMeeting, HeaderPreprint, HeaderOa },
    data() {
        return {
            pN: '',
            pageName: '检索结果页',
            sideData: [
                {
                    title: '',
                },
            ],
            currentPage: 1,
            pageSize: 10,//8,
            pageNum: 1,
            sort: 1, //1相关度2在线时间
            subjectData: [],
            timeData: [],
            classifyData: [],
            oaData: [],
            total: 0,
            resultData: [],

            activeClass: '',
            activeResource: '',
            activeOa: '',
            activeDate: '',

            classifyId: '',
            resourceType: null, //大搜索上面的类型
            resourceTypeList: [], //['COLLECTED_PAPERS', 'WENJI', 'ARTICLE'],
            bookReleaseDate: '',
            oaVal: '',

            secondTitle: '', //二次检索标题
            secondAuthor: '', //二次检索作者
            secondKeyword: '', //二次检索关键词
            secondStartDate: '', //二次检索开始时间
            secondEndDate: '', //二次检索结束时间

            libraryId: '', //子库页面 子库id

            highKey: '',
        }
    },
    watch: {
        $route: 'init',
    },
    methods: {
        init() {
            this.currentPage = 1
            this.pageNum = 1
            this.sort = 1
            this.classifyId = ''



            // if (this.$route.query.searchType) {
            //     this.resourceType = this.$route.query.searchType
            //     // this.resourceTypeList = []
            //     // this.resourceTypeList.push(this.$route.query.searchType)
            // } else {
            //     this.resourceType = null
            //     // this.resourceTypeList = [] //['COLLECTED_PAPERS', 'WENJI', 'ARTICLE'],
            // }
            this.bookReleaseDate = ''
            this.oaVal = ''

            this.activeClass = ''
            this.activeResource = ''
            this.activeOa = ''
            this.activeDate = ''

            // this.secondTitle = '' //二次检索标题
            // this.secondAuthor = '' //二次检索作者
            // this.secondKeyword = '' //二次检索关键词
            // this.secondStartDate = '' //二次检索开始时间
            // this.secondEndDate = '' //二次检索结束时间

            this.libraryId = ''

            if (this.$route.query.oaType) {
                this.activeOa = this.$route.query.oaType
                this.oaVal = this.$route.query.oaType
            }
            if (this.$route.query.pN) {
                this.pN = decodeURI(this.$route.query.pN)
            } else {
                this.pN = ''
            }

            if (this.$route.name == 'oaS') {
                //二级子库页面
                // this.pageName = this.$route.query.title
                // this.libraryId = this.$route.query.id
                this.getSideFormatYearClass()
                this.search()
            } else if (this.$route.name == 'pS') {
                //检索结果页
                // this.pageName = '检索结果页'
                this.getSideFormatYearClass()
                this.search()
            }
        },
        deleteLeft(num, item) {
            if (num == 0) {
                this.activeOa = 'ALL'
                this.oaVal = ''
            } else if (num == 1) {
                // 内容分类
                this.activeResource = ''
                this.resourceTypeList = []
                // var x = this.resourceTypeList.indexOf(this.resourceTypeList.filter(d=>d.id == item.id)[0])
                // this.resourceTypeList.splice(x, 1)
            } else if (num == 2) {
                // 学科
                this.activeClass = ''
                this.classifyId = ''
            } else if (num == 3) {
                // 年份
                this.activeDate = ''
                this.bookReleaseDate = ''
            }
            this.pageNum = 1
            this.currentPage = 1
            this.sort = 1
            this.getSideFormatYearClass()
            this.search()
        },
        goCollected(id, title) {
            // collected?id=472&title=新媒体与社会
            this.$router.push({ path: '/c/c', query: { id: id, pN: encodeURI(this.pageName) } })
            // title: title,
        },
        goCollectedDetail(id, title) {
            this.$router.push({ path: '/collectionDetail/c', query: { id: id, pN: encodeURI(this.pageName) } })
        },
        goArticleDetail(item) {
            // OA课件 OA数据集  OA视频
            if (this.$route.name == 'oaS') {
                if (item.oaType == 'OA课件') {
                    if (item.mediaPath) {
                        const a = document.createElement('a')
                        a.href = 'https://www.jikan.com.cn/admin/api/news/download?id='+item.id
                        a.click()
                    } else {
                        return
                    }
                    // this.$router.push({ path: '/nD/n', query: { id: item.id, pN: encodeURI(this.pageName) } })
                } else if (item.oaType == 'OA数据集') {
                    this.$router.push({ path: '/nD/n', query: { id: item.id, pN: encodeURI(this.pageName) } })
                } else if (item.oaType == 'OA视频') {
                    this.$router.push({ path: '/vD/v', query: { id: item.id, pN: encodeURI(this.pageName) } })
                } else {
                    this.$router.push({ path: '/aD/a', query: { id: item.id, pN: encodeURI(this.pageName) } })
                }
            } else {
                if(item.resourceType == 'ALONE_ARTICLE') {
                    this.$router.push({ path: '/pD/p', query: { id: item.id, pN: encodeURI(this.pageName) } })
                    // .slice(0, 13)
                } else {
                    this.$router.push({ path: '/aD/a', query: { id: item.id, pN: encodeURI(this.pageName) } })
                }

            }

        },

        switchSide(item, type) {
            if (type == 'OA类型') {
                this.activeOa = item.name
                this.oaVal = item.value
            } else if (type == '学科') {
                this.activeClass = item.name
                this.classifyId = item.value
            } else if (type == '内容分类') {
                this.activeResource = item.name
                this.resourceTypeList = []
                this.resourceTypeList.push(item.value)
            } else if (type == '年份') {
                this.activeDate = item.name
                this.bookReleaseDate = item.value
            }
            // console.log(this.activeClass, this.activeResource, this.activeDate)

            this.pageNum = 1
            this.currentPage = 1
            this.sort = 1
            this.getSideFormatYearClass()
            this.search()
        },
        secondSearch() {
            // this.currentPage = 1
            // this.pageNum = 1

            this.sort = 1
            this.classifyId = ''
            this.resourceTypeList = [] //['COLLECTED_PAPERS', 'WENJI', 'ARTICLE'],
            this.oaVal = ''
            this.bookReleaseDate = ''
            this.pageNum = 1
            this.currentPage = 1
            this.pageSize = 10

            this.activeClass = ''
            this.activeResource = ''
            this.activeOa = ''
            this.activeDate = ''
            // this.init()

            this.getSideFormatYearClass()
            this.search()
        },
        search() {
            var _this = this
            var curA = {}
            //检索结果页
            // var x = ''
            // var y = ''
            // if (this.secondStartDate) {
            //     x = this.secondStartDate.slice(0, 10)
            // }
            // if (this.secondEndDate) {
            //     y = this.secondEndDate.slice(0, 10)
            // }
            // if (Date.parse(this.secondStartDate) > Date.parse(this.secondEndDate)) {
            //     y = this.secondStartDate.slice(0, 10)
            //     x = this.secondEndDate.slice(0, 10)
            // } else {
            //     x = this.secondStartDate.slice(0, 10)
            //     y = this.secondEndDate.slice(0, 10)
            // }
            // console.log('this.classifyId', this.classifyId)
            // curA = {
            //     type: 1,
            //     sort: this.sort, //1相关度2在线时间
            //     searchKeyword: this.$route.query.searchKey ? this.$route.query.searchKey.trim() : '',

            //     classifyId: this.classifyId,
            //     // resourceType: this.resourceType,
            //     resourceTypeList: this.resourceTypeList, //['COLLECTED_PAPERS', 'WENJI', 'ARTICLE'],
            //     bookReleaseDate: this.bookReleaseDate,

            //     titleKeyword: this.secondTitle, //二次检索标题
            //     authorKeyWord: this.secondAuthor, //二次检索作者
            //     keyWordCn: this.secondKeyword, //二次检索关键词
            //     // publicationStartDate: x, //二次检索开始时间
            //     // publicationEndDate: y, //二次检索结束时间
            //     pageNo: this.pageNum,
            //     pageSize: this.pageSize,
            //     libraryId: this.libraryId, //二级子库
            // }
            if (this.$route.name=='oaS') {
                curA = {
                    type: 1,
                    sort:  this.sort, //1相关度2在线时间
                    searchKeyword: this.$route.query.searchKey ? this.$route.query.searchKey.trim() : '',
                    oaType: this.oaVal? this.oaVal : 'ALL', //"ALL",
                    pageNo: this.pageNum,
                    pageSize: this.pageSize,
                    classifyId: this.classifyId,
                    bookReleaseDate: this.bookReleaseDate,
                    // resourceTypeList: this.resourceTypeList,
                }
            } else if (this.$route.name=='pS') {
                curA = {
                    type: 1,
                    sort:  this.sort, //1相关度2在线时间
                    searchKeyword: this.$route.query.searchKey ? this.$route.query.searchKey.trim() : '',
                    resourceType: "ALONE_ARTICLE",
                    pageNo: this.pageNum,
                    pageSize: this.pageSize,
                    classifyId: this.classifyId,
                    bookReleaseDate: this.bookReleaseDate,
                }
            }

            this.$http({
                method: 'post',
                url: '/admin/api/search/find',
                data: curA,
            }).then((res) => {
                if (res.data.code == 0) {
                    _this.total = res.data.data.total
                    _this.resultData = res.data.data.rows
                }
            })
        },
        getSideFormatYearClass() {
            var _this = this
            var curA = {}
            //普通检索、二级子库
            // var x = ''
            // var y = ''
            // if (this.secondStartDate) {
            //     x = this.secondStartDate.slice(0, 10)
            // }
            // if (this.secondEndDate) {
            //     y = this.secondEndDate.slice(0, 10)
            // }
            // if (Date.parse(this.secondStartDate) > Date.parse(this.secondEndDate)) {
            //     y = this.secondStartDate.slice(0, 10)
            //     x = this.secondEndDate.slice(0, 10)
            // } else {
            //     x = this.secondStartDate.slice(0, 10)
            //     y = this.secondEndDate.slice(0, 10)
            // }

            if (this.$route.name=='oaS') {
                curA = {
                    type: 1,
                    sort: 1,
                    searchKeyword: this.$route.query.searchKey ? this.$route.query.searchKey.trim() : '',
                    oaType: this.oaVal? this.oaVal : 'ALL', //"ALL",
                    pageNo: this.pageNum,
                    pageSize: 30,
                    classifyId: this.classifyId,
                    bookReleaseDate: this.bookReleaseDate,
                    // resourceTypeList: this.resourceTypeList, //['COLLECTED_PAPERS', 'WENJI', 'ARTICLE'],

                }
            } else if (this.$route.name=='pS') {
                curA = {
                    type: 1,
                    sort: 1,
                    searchKeyword: this.$route.query.searchKey ? this.$route.query.searchKey.trim() : '',
                    resourceType: "ALONE_ARTICLE",
                    pageNo: this.pageNum,
                    pageSize: 30,
                    classifyId: this.classifyId,
                    bookReleaseDate: this.bookReleaseDate,
                }
            }
            // curA = {
            //     type: this.$route.query.type ? parseInt(this.$route.query.type) : 1,
            //     searchKeyword: this.$route.query.searchKey ? this.$route.query.searchKey.trim() : '',
            //     // resourceTypeList:  ['COLLECTED_PAPERS', 'WENJI', 'ARTICLE'],

            //     // classifyId: this.classifyId,
            //     resourceType: this.resourceType,
            //     // resourceTypeList: this.resourceTypeList, //['COLLECTED_PAPERS', 'WENJI', 'ARTICLE'],
            //     // bookReleaseDate: this.bookReleaseDate,

            //     // titleKeyword: this.secondTitle, //二次检索标题
            //     // authorKeyWord: this.secondAuthor, //二次检索作者
            //     // keyWordCn: this.secondKeyword, //二次检索关键词
            //     // publicationStartDate: x, //二次检索开始时间
            //     // publicationEndDate: y, //二次检索结束时间
            //     pageNo: 1,
            //     pageSize: 10,

            //     libraryId: this.libraryId, //二级子库
            // }

            this.$http({
                method: 'post',
                url: '/admin/api/search/filterCondition',
                data: curA,
            }).then((res) => {
                if (res.data.code == 0) {
                    var x = res.data.data
                    x.forEach((e) => {
                        if (e.typeName == '内容类型') {
                            _this.subjectData = e.conditions
                        } else if (e.typeName == '出版时间') {
                            _this.timeData = e.conditions
                        } else if (e.typeName == '学科分类') {
                            _this.classifyData = e.conditions
                        }
                        if (_this.$route.name=='oaS') {
                            if (e.typeName == 'OA类型') {
                                _this.oaData = e.conditions
                            }
                        }
                    })
                }
            })
        },
        switchSort(num) {
            this.sort = num
            this.currentPage = 1
            this.pageNum = 1
            this.search()
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.pageNum = val
            this.search()
        },
    },
    created() {
        this.init()
    },
}
</script>

<style lang="scss" scoped>
.preprint-search-page {
    .search {
        min-height: 700px;
        background: #fefefe;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
        padding: 30px 30px 0;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        .left-search {
            width: 290px;
            div {
                h5 {
                    height: 56px;
                    line-height: 56px;
                    background: #bd1a2d;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    cursor: default;
                    padding-left: 30px;
                    box-sizing: border-box;
                    font-size: 18px;
                    img {
                        width: 20px;
                        height: 20px;
                        margin-right: 8px;
                    }
                }
            }

            ul {
                // border: 1px solid #e6e6e6;
                max-height: 188px;
                border: 1px solid #f5f5f5;
                box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
                margin-bottom: 24px;

                li {
                    padding-left: 30px;
                    box-sizing: border-box;
                    height: 62px;
                    // line-height: 62px;
                    font-size: 18px;
                    border-bottom: 1px solid #e6e6e6;
                    background: #fff;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    cursor: default !important;
                    line-height: 1;
                    a {
                        display: block;
                        font-size: 18px;
                        color: #333;
                        cursor: pointer;
                        width: 230px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    span {
                        font-size: 16px;
                        color: #666;
                    }
                    i {
                        margin-right: 10px;
                        cursor: pointer;
                    }
                }
                .active-left {
                    background: #f7f7f7;
                    color: #bd1a2d;
                    cursor: pointer;
                    a {
                        color: #bd1a2d;
                    }
                }
                li:hover {
                    background: #f7f7f7;
                    cursor: pointer;
                }
                li:last-child {
                    border-bottom: none;
                }
            }
            ul.oa-ul {
                max-height: 300px;
            }
            div.class-wrap {
                ul {
                    max-height: 620px;
                    overflow-y: auto;
                }
            }
            div.year-wrap {
                ul {
                    max-height: 620px;
                    overflow-y: auto;
                }
            }
        }
        .right-search {
            width: 820px;
            padding-bottom: 26px;
            .second-search-wrap {
                width: 100%;
                .second-search {
                    width: 100%;
                    height: 80px;
                    box-sizing: border-box;
                    padding: 20px 30px 20px 20px;
                    background: #f0f0f0;
                    display: flex;
                    .second-title {
                        width: 101px;
                    }
                    .second-author {
                        width: 101px;
                    }
                    .second-keywords {
                        width: 121px;
                    }
                    .second-date {
                    }
                    .second-input {
                        background: #ffffff;
                        border-radius: 2px;
                        border: none;
                        margin-right: 10px;
                        padding-left: 10px;
                        box-sizing: border-box;
                        color: #666666;
                        font-size: 14px;
                    }
                    button {
                        width: 147px;
                        color: #fff;
                        border: none;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        background: #a91728;
                        margin-left: 10px;
                    }
                    button:hover {
                        opacity: 0.8;
                    }
                }
                .search-option {
                    display: flex;
                    justify-content: space-between;
                    margin: 13px 0 12px;

                    div {
                        display: flex;
                        color: #404040;
                        font-size: 14px;
                        span {
                            display: block;
                        }
                    }
                    .search-option1 {
                        display: block;
                        width: 100%;
                        padding-left: 19px;
                        //flex-wrap: wrap;
                        //justify-content: space-between;
                        .total {
                            display: inline-block;
                            font-size: 14px;
                            margin-right: 40px;
                            span {
                                display: inherit;
                                color: #ffa200;
                            }
                        }
                        a {
                            margin-right: 30px;
                        }
                        .search-option2 {
                            padding-right: 2px;
                            float: right;
                            span.active-sort {
                                color: #ffa200;
                            }
                            .sort1 {
                                margin-right: 4px;
                                cursor: pointer;
                            }
                            .sort2 {
                                margin-left: 4px;
                                cursor: pointer;
                            }
                        }
                    }

                }
                ul.result-list {
                    li {
                        padding: 20px;
                        background: #ffffff;
                        border: 1px solid #f5f5f5;
                        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
                        margin-bottom: 24px;
                        display: flex;
                        div {
                            position: relative;
                            img {
                                width: 148px;
                                height: 198px;
                                background: #ceb08d;
                                border: 1px solid #e6e6e6;
                                box-shadow: 0px 3px 9px 0px rgba(15, 11, 5, 0.29);
                                // margin-right: 13px;
                                cursor: pointer;
                            }
                        }
                        .tag-book::after {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 42px;
                            height: 42px;
                            background: url(../assets/img/tag-book.png);
                        }
                        .tag-wenji::after {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 42px;
                            height: 42px;
                            background: url(../assets/img/tag-wenji.png);
                        }

                        .right-result {
                            h4 {
                                display: flex;
                                justify-content: space-between;
                                p.included-database {
                                    padding-top: 6px;
                                    min-width: 100px;
                                    span {
                                        float: left;
                                        height: 20px;
                                        margin-left: 10px;
                                        display: block;
                                        font-size: 12px;
                                        color: #bd1a2d;
                                        padding: 0 10px;
                                        line-height: 20px;
                                        border: 0.8px solid #a91728;
                                        box-sizing: border-box;
                                        border-radius: 10px;
                                        white-space: nowrap;
                                        margin-bottom: 4px;
                                        float: right;
                                    }
                                    // span:last-child {
                                    //     min-width: 100px;
                                    // }
                                }
                            }
                            h5 {
                                font-size: 18px;
                                font-family: Microsoft YaHei;
                                line-height: 19px;
                                margin-bottom: 15px;
                                cursor: pointer;
                            }
                            p {
                                font-size: 16px;
                                line-height: 17px;
                                color: #666;
                                margin-bottom: 14px;
                                span {
                                    font-size: 16px;
                                    line-height: 17px;
                                    color: #333;
                                }
                            }
                            p:last-child {
                                margin-bottom: 0;
                                line-height: 26px;
                                margin-top: -5px;
                                text-overflow: -o-ellipsis-lastline;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                line-clamp: 2;
                                -webkit-box-orient: vertical;
                                span {
                                    color: #666;
                                }
                            }
                        }
                        .w-result {
                            width: 617px;
                        }
                        .article-result {
                            h5.article-title {
                                position: relative;
                                display: flex;
                                img {
                                    width: 20px;
                                    height: 20px;
                                    background: #fff;
                                    border: none;
                                    box-shadow: none;
                                }
                                .article-icon-p {
                                    width: 15px;
                                    height: 17px;
                                    display: block;
                                    background: url(../assets/img/tag-title.png);
                                    margin-right: 8px;
                                }
                                .article-icon-preprint {
                                    width: 19px;
                                    height: 22px;
                                    display: block;
                                    margin-right: 8px;
                                    background: url(../assets/img/oa/icon-ssap.png);
                                    background-size: 100% 100%;
                                    margin-top: -3px;
                                }
                                .article-icon-video {
                                    width: 19px;
                                    height: 19px;
                                    display: block;
                                    margin-right: 8px;
                                    background: url(../assets/img/oa/icon-video.png);
                                    background-size: 100% 100%;
                                    margin-top: 0px;
                                }
                                .article-icon-pdf {
                                    content: 'pdf';
                                    background: #bd1a2d;
                                    width: 24px;
                                    height: 24px;
                                    line-height: 26px;
                                    color: #fff;
                                    font-size: 11px;
                                    text-align: center;
                                    border-radius: 4px;
                                    margin-right: 6px;
                                    margin-top: -4px;
                                    cursor: auto;
                                }

                                .article-title-auto-pdf {
                                    cursor: auto;
                                }
                                .article-icon-collect {
                                    width: 19px;
                                    height: 19px;
                                    display: block;
                                    margin-right: 8px;
                                    background: url(../assets/img/oa/icon-collect.png);
                                    background-size: 100% 100%;
                                    margin-top: -2px;
                                }


                            }
                            // h5.article-title::before {
                            //     content: '';
                            //     width: 15px;
                            //     height: 17px;
                            //     display: block;
                            //     background: url(../assets/img/tag-title.png);
                            //     position: absolute;
                            //     left: 0;
                            //     top: 0;
                            // }
                        }
                    }
                }
            }
            .pagination-wrap {
                display: flex;
                justify-content: center;
            }
        }
    }
}
</style>
